.dash-box{
    .links{
        max-width: 600px;
    }

    background-color: white;
        //height: 70vh;
        //overflow-y: auto;
        //width: 74vw;
        border-radius: 10px;
        padding-top: 25px!important;
        padding-bottom: 20px!important;
        border: 1px solid #dbdfea;

    .tool-card{
        border: 1px #e5e9f2 solid;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
        //width: 40%;
        
    }

    .spinner-border{
        display: grid!important;
        color: white!important;
    }

    .boxed{
        padding-right: 30px;
        padding-left: 30px;
    }

    .g-gs > div{
        padding: 50px !important;
    }

    .quick-link{
        width: 80%;
        font-family: Nunito, sans-serif!important;
        font-weight: 550;
        font-size: 15px;
        color: white;
        background-color: var(--site-primary-color);
        opacity: 0.8;
        border: 0px;
        padding:12px;
        border-radius: 5px;
    }

    .quick-link:hover{
        background-color: var(--site-primary-color);
        opacity: 0.9;
    }

    .quick-link:focus{
        outline-color: var(--site-primary-color);
        opacity: 0.9;
    }

    .upper-section{
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px #e5e9f2 solid;
        padding-top: 25px;
        align-items: flex-end;
        padding-bottom: 10PX;
    }

    .item-images{
        width: 30%;
        float: right;
        margin-top: 5%;
    }

    .rate-box{
        //display: inline-flex;
        align-items: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        justify-content: center;
    }

    .quick-title{
        font-family: Nunito, sans-serif!important;
        font-weight: 600;
        color: #2d3d52;
        font-size: large;
        padding-top: 30px;
        margin-bottom: 20px;
        //margin-left: 30px;
    }

    .welcome{
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
        font-weight: 600;
        font-size: 25px;
        //padding-top: 20px;
        margin-bottom: 20px;
    }

    .hello{
        margin-bottom: 0px!important;
        line-height: 25px;
        font-weight: 300;
        // font-size: 20px;
    }

    .quick-sect{
        padding-bottom: 20px;
        padding-right: 0px!important;
        text-align: right!important;
        
    }

    .right-card{
        width: 32%;
        border: 1px #e5e9f2 solid;
        box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
        height: 300px;
        border-radius: 15px;
        padding: 25px;
        background-color: #1c2b46;
        
    }

    .left-card{
        width: 32%;
        border: 1px #e5e9f2 solid;
        box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
        height: 300px;
        border-radius: 15px;
        padding: 34px;
        
    }

    .cards-sect{
        display: inline-flex;
        width: 100%;
        justify-content: space-between!important;
        margin-bottom: 25px;
        padding-bottom: 60px;
        border-bottom: 1px #e5e9f2 solid;

    }
    
    .one{
        height: 50%!important;
    }

    .two{
        height: 50%!important;
    }

    .exchange-rate{
        //margin-top: 28px;
        //width: 180px;
        // margin-left: auto;
        // margin-right: auto;
        font-family: Nunito, sans-serif!important;
        font-size: 5rem;
        font-weight: 400;
        color: white!important;
    }

    .reload{
        width: 32px;
    }

    .box-top-header{
        //color: white;
        font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans", sans-serif!important;
        font-size: 15px;
        padding-right: 0px!important;
        padding-left: 0px!important;

    }
    .box-top-header2{
        color: white;
        font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans", sans-serif!important;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        
    }

    .bottom-message{
        color: white;
        font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans", sans-serif!important;
        font-size: 11px;
        font-weight: 500;
        text-align: center;
        margin-top: 15px;
       
    }
    .size-set{
        height: 50%;
    }

    .bot-div{
        border-top: 1px black solid;
        padding-top: 22px;
    }

    .card-tit{
        font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans", sans-serif!important;
        font-size: 12px;
        font-weight: 500;
        color: white;
        background-color: #1c2b46;
        padding: 3px!important;
        border-radius: 5px;
        padding-right: 5px!important;
        padding-left: 5px!important;
        max-width: fit-content;
    }

    .card-dat{
        margin-top: 5px;
        //float: right;
        font-family: Nunito, sans-serif!important;
        font-size: 22px;
        font-weight: 500;
        color: var(--site-primary-color);
        margin-bottom: -6px!important;

    }

    .view-all{
        font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans", sans-serif!important;
        color: #1c2b46;
        font-size: 15px;
        font-weight: 500;
        text-decoration: underline;
        margin-left: 3px;

    }

    .date-card{
        border: 1px #e5e9f2 solid;
        box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
        height: 100px;
        border-radius: 15px;
        padding: 25px;
        width: 32%!important;
    }

    .vouch-img{
        width: 25px;
        margin-right: 20px;
    }

    .set-sm-card{
        width: 100%;
        display: inline-flex ;
        justify-content: space-between;
        padding-bottom: 40px;
        //padding-top: 20px;
        //border-bottom: 1px #e5e9f2 solid;

    }

    .bott-bott{
        padding-bottom: 30px;
        //border-bottom: 1px #e5e9f2 solid;
    }

    .top-top{
        padding-top: 10px;
    }

    .sm-card-content{
        display: inline-flex;
    }

    .sm-card-text{
        font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans", sans-serif!important;
        font-size: 15px;
        font-weight: 500;
    }

    .sm-card-num{
        margin-top: 5px;
        //float: right;
        font-family: Nunito, sans-serif!important;
        font-size: 18px;
        font-weight: 600;
        color: var(--site-primary-color);
        text-align: center;
    }

    .field{
        border: 1px #e6e6e6 solid;
        border-radius: 5px;
        font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans", sans-serif!important;
    }

    .recent-head-set{
        height: 45px;
        padding: 10px;
        background-color: #1c2b46;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        display: flex;
    }

    .head-txt{
        color: #c7ccd1!important;
        font-size: 12PX;
        font-weight: 600;
    }

    .img-arrow{
        width: 14px!important;
    }

    .arr-div{
        text-align: right!important;
    }

    .txt{
        font-weight: 500;
    }

    .recent-body-set{
        height: 80px;
        padding: 10px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        display: flex;
        border-bottom: 1px #e6e6e6 solid;
    }

    .sync-container{
        background-color: white;
        width: fit-content;
        margin: auto;
        border-radius: 50%;
    }

    .sppinner{
        padding-top: 65px;
        margin-bottom: 74px;
    }

    .SpinnerEdit{
        margin-top: 80px;
        margin-bottom: 59px;
    }

    @media (max-width:1480px){
        .sppinner{
            padding-top: 65px;
            margin-bottom: 45px;
        }

        .SpinnerEdit{
            margin-top: 65px;
            margin-bottom: 45px;
        }

        .right-card{
            padding: 15px;
            height: 230px;
        }
        .left-card{
            padding: 25px;
            height: 230px;
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .size-set{
            height: 50%;
        }
        .exchange-rate{
            font-size: 4rem;
            line-height: 95px;
        }
        .box-top-header2{
            font-size: 12px;
        }
        .bottom-message{
            font-size: 9px;
        }
        .card-tit{
            font-size: 10px;
        }
        .bot-div{
            padding-top: 11px;
        }
        .card-dat{
            font-size: 18px;
        }
        .view-all{
            font-size: 12px; 
        }
        .quick-link{
            font-size: 14px;
            font-weight: 500;
            width: 95%;
        }
        .welcome{
            font-size: 20px;
        }

    }

   
    
    
}