.services{
    .css-13cymwt-control{
        height: 45px!important;
    }
    
    .amt-div{
        display: inline-flex;
    }
    .curr-inpt{
        width: 60px!important;
    }
    
    // .css-qbdosj-Input{
    //     height: 45px!important;
    // }
}
