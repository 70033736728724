.nk-content.row.align-self-center.h-100 {
  margin: auto;
  background-image: url('/public/images/login-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.nk-content.row.align-self-center.h-100::before{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #f1f1f1 !important;
    caret-color: white!important;
}

.nk-error-head {
  font-size: 40px;
  font-weight: 700;
  background: -webkit-linear-gradient(#1ee0ac, green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.9;
}

.fixed-if{
  vertical-align: bottom!important;
}

.view-but{
  /* border: 1px #c6c7c7 solid; */
  font-weight: 500;
  border-radius: 10px;
  color: #526484!important;
  background-color: white;
  font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  padding-left: 10px!important;
  font-size: 13px;
}

.fixed-weight{
  font-weight: 600!important;
}

.img-arrow{
  width: 16px!important;
  margin-left: 10px;
}

.form-control-wrap.mt-4 {
  display: flex;
}
h1.fail {
  font-size: 48px;
  font-weight: 800;
  color: #e85347;
}
.text-align-center {
  font-size: 15px;
  font-weight: 600;
  color: black;
}

img.trans-img {
  max-width: 21%;
  margin-top: -44px;
  border: 2px solid red;
  border-radius: 50%;
  margin-bottom: 7px;
}
img.trans1-img {
  max-width: 27%;
  margin-top: -48px;
  border: 2px solid green;
  border-radius: 50%;
  margin-bottom: 12px;
}

.col-4.bbtn {
  padding-right: 0px;
  padding-left: 0px;
}
.col-6.bbtn {
  padding-right: 0px;
  padding-left: 8px;
}
.col-6.bbt {
  padding-right: 3px;
  padding-left: 0px;
  margin-left: -11px;
}
button.btn.btn-secondary.btn-md {
  padding: 9px 43px;
}
button.btn.btn-warning.btn-md {
  padding: 9px 43px;
}

.All-filter.row {
  background-color: #f7f7f8;
  padding-bottom: 25px;
  margin-right: -10px;
  margin-left: -10px;
  margin-top: -2px;
  margin-bottom: -20px;
}
.-mt-2 {
  margin-top: -5px;
}

.css-13cymwt-control{
  min-height: 36px!important;
}

.form-control:focus{
  outline: none!important;
  box-shadow: none!important;
}

@media (max-width: 479px) {
  .col-4.bbtn {
    padding-left: 23px;
  }
  button.btn.btn-warning.btn-md {
    padding: 12px 130px;
  }
  button.btn.btn-secondary.btn-md {
    padding: 12px 131px;
  }
  .row.mt-4.bbtn {
    margin-top: 6px !important;
  }
  .col-6.bbt {
    padding-right: 10px;
    padding-left: 0px;
    margin-left: 7px;
  }
}
