.nk-content {
  margin-top: -14px;
}
.inputCheck::after {
  color: tomato;
}

.css-13cymwt-control {
  height: 45px;
}

.css-t3ipsp-control {
  height: 45px;
  box-shadow: none !important;
}

.css-t3ipsp-control:focus {
  border: 1px #fe2502 solid !important;
  outline: 0px !important;
}

.css-t3ipsp-control:hover {
  outline: 0px;
  border: 1px #fe2502 solid !important;
}

.css-13cymwt-control:focus {
  outline: 0px;
  border: 0px;
}

#checker:checked:before {
  background-color: tomato;
}

.inputCheck {
  border: #e85347 solid 2px;
}
.padding {
  padding-left: 0 !important;
}
/* .nk-block.nk-block-lg {
  width: 100%;
} */
.nk-tb-col.nk-tb-col-status.list {
  cursor: pointer;
}

label.form-label.buttonLabel {
  visibility: hidden;
}

.spread-pad {
  padding: 0px !important;
}

button.btn.btn-lg.reset:hover {
  background-color: red;
  color: white;
}

.nk-order-ovwg-data.sell .amount,
.nk-order-ovwg-data.sell .title .icon {
  color: #f4bd0e;
}
.nk-order-ovwg-data.sell {
  border-color: #f4bd0e;
}
.nk-order-ovwg-data.reject {
  border-color: #e85347;
}
.nk-order-ovwg-data.reject .amount,
.nk-order-ovwg-data.reject .title .icon {
  color: #e85347;
}

label.form-label.trans {
  visibility: hidden;
}
.submit {
  padding: 1rem 5.3rem !important;
}
.back {
  padding: 1rem 4.6rem !important;
}
.update {
  padding: 1rem 8.8rem !important;
}
.reset {
  padding: 1rem 7.5rem;
}
/* .printme {
  display: none;
} */
@media print {
  .no-printme {
    display: none;
  }
  .printme {
    display: block;
  }
}

.voucher-link {
  color: blue;
}
