.ReactModal__Overlay--after-open {
  overflow: scroll;
}
.d-block {
  display: block !important;
}

.invdownloadimg{
  width: 50px;
  cursor: pointer;

}

.invdownloadimg:hover{
  width: 55px;
  margin-right: -3px;
  
}
