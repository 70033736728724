.customer-drop-down {
  position: absolute;
  width: 100%;
  padding: 10px;
  background: #ededed !important;
  z-index: 1;
}
.invoice-pad {
  height: 190px;
}
.invoice-pad.sm {
  height: 100px;
}
.customer-drop-down.sm {
  position: relative;
}

.add-fix {
  margin-top: 0px !important;
  display: flex !important;
}
.fix-add-vouch {
  background-color: #1c2b46;
  color: white !important;
  width: 180px;
  padding: 16px;
  text-decoration: none !important;
  font-size: 13px;
  font-weight: 400;
}
.fix-add-vouch:focus {
  border: 1px #1c2b46 solid;
}
.ex-mg {
  margin-top: 2.5rem !important;
}
.inphgt {
  height: 45px !important;
}
.fix-inv-btn {
  padding: 15px !important;
}
.based {
  align-items: end !important;
  display: grid;
}
.based-update {
  margin-top: 30px;
}
.add-btn {
  height: 44px !important;
  width: 100%;
}
.fix-up {
  margin-top: 20px;
}
.quantity {
  display: inline-flex;
  width: 50px;
}
div[disabled] {
  pointer-events: none;
  opacity: 0.6;
}
span.customer-name,
.customer-name {
  font-size: 12px;
  font-weight: normal;
  margin-right: 15px;
}

span.customer-email {
  color: #fe2502;
  font-size: 8px;
}
.customer-drop-down li {
  margin-bottom: 7px;
  cursor: pointer;
}
.invoice {
  padding: 1rem 0rem !important;
}
.invoice-list {
  padding: 1rem 3.5rem !important;
}
.ni.ni-lg {
  font-size: 28px;
}
.new {
  color: var(--site-primary-color);
}
.cancel-btn {
  padding: 0;
  border: 0;
  position: absolute;
  right: 4px;
}
.cancel-cont {
  position: relative;
}
