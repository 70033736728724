:root {
  --site-primary-color: #fe2502;
  --site-secondary-color: #cc1d01;
}

.btn-primary {
  background-color: var(--site-primary-color) !important;
  border-color: var(--site-primary-color) !important;
  opacity: 0.7;
}
.link-primary {
  color: var(--site-primary-color) !important;
}
.user-avatar.sm {
  background-color: var(--site-primary-color) !important;
}
.dropdown-menu-s1 {
  border-top: 3px solid var(--site-primary-color) !important;
}
.btn-lg {
  justify-content: center;
}
/* View button */
.btn-primary:hover {
  background-color: #526484 !important;
  border-color: #526484 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.css-yk16xz-control, .css-1pahdxg-control{
  height: calc(2.625rem + 2px);
}