.place-holder{
    height: 40vh;
    border: solid #dbdfea 1px;
    width: 100%;
    border-radius: 5px;
}
.place-holder-text{
    width: fit-content;
    margin: auto;
    color: #9a9b9e;
    padding-top: 18vh;
    font-family: Nunito, sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
}
.archive-select{
    border: solid hsl(0, 0%, 80%) 1px;
    color: rgb(75, 74, 74);
    height: 41px;
    padding: 8px;
    border-radius: 5px;
    
}
.archive-select:focus{
    outline: none;
}
.filter-sect2{
    /* border:solid #dbdfea 1px; */
    margin-bottom: 20px;
    border-radius: 5px;
}

.archive-box{
    border: solid #dbdfea 1px;
    padding: 30px;
    border-radius: 5px;
    display: grid!important;
    grid-template-columns: auto auto auto auto auto;
}

.Folder-box{
    width: fit-content;
    text-align: center;
    font-family: Nunito, sans-serif;
    font-weight: 700;
    padding: 10px;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    
    
}

/* .Folder-box:hover{
    border: solid tomato 1px;
} */

.css-t3ipsp-control{
    height: 45px!important;
}

.archiveText{
    font-size: 12px;
    max-width: 150px;
    color: grey;
    margin-top: 10px;
}

.archiveText:hover{
    text-decoration: underline;
}