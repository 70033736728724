.service-categories-container{
  .service_cat_item .card:hover{
    background-color: #dbdfea !important;
  }
.border {
  border-radius: unset !important;
}
.svg_icon {
  width: 60% !important;
}
.block{
  width: 100%;
}
.equal-witdth{
  min-width: 461px;
  max-width: 461px;
}
}