.dash-box-report {
  background-color: white;
  border-radius: 10px;
  padding-top: 25px !important;
  padding-bottom: 20px !important;
  border: 1px solid #dbdfea;

  .boxed {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .tab-button {
    padding: 10px;
    border: 0px;
    width: 140px;
    font-size: 13px;
    font-weight: 450;
    font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans",
      sans-serif !important;
  }

  .tab-button:focus {
    outline: none;
  }

  .tab-select {
    padding-top: 20px;
  }

  .table-content-filter {
    //padding: 10px;
    border: 1px solid #f1f1f1;
  }

  .filter {
    width: 100%;
    display: inline-flex !important;
    justify-content: space-between;
    width: 100%;
  }

  .filter-content {
    display: inline-flex;
    width: 100%;
  }

  .date {
    border: 1px solid hsl(0, 0%, 80%) !important;
    border-radius: 3px !important;
    color: hsl(0, 0%, 20%) !important;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
  }

  .date:focus {
    outline: none !important;
  }

  .fromto-text {
    font-size: 12px;
    color: black;
    margin-right: 12px;
    margin-top: 12px;
    font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans",
      sans-serif !important;
  }

  .filter-btn {
    background-color: tomato;
    font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans",
      sans-serif !important;
    color: white;
    font-size: 13px;
    font-weight: 500;
    height: 40px;
    width: 100%;
  }

  .title {
    font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans",
      sans-serif !important;
    font-size: 15px;
    font-weight: 500;
  }

  @media (min-width: 768px) {
    .col-md-3 {
      max-width: unset;
    }
  }

  .filter-sect {
    padding: 20px;
  }

  .no-data {
    text-align: center;
    padding: 150px;
    border-right: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    font-family: Nunito, sans-serif !important;
    font-size: 0.9rem;
    font-weight: 400;
    color: #9a9b9e;
  }

  .export-btn {
    width: 100%;
  }

  .css-13cymwt-control {
    height: 40px !important;
  }

  .display-box {
    border: 1px solid #f1f1f1;
  }

  .table-head {
    height: 45px;
    padding: 10px;
    background-color: #1c2b46;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    display: flex;
    color: #f1f1f1 !important;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid #f1f1f1;
  }

  .table-list {
    height: 65px;
    padding: 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    display: flex;
    font-size: 13px;
    font-weight: 400;
    border-bottom: 1px solid #f1f1f1;
  }
}
