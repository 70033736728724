.settings-box {
  background-color: white;
  width: 100%;
  height: 73vh;
  border-radius: 10px;
  padding: 50px !important;

  .section-set {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding: 20px;
    height: 125px;
    border-bottom: 1px #f1f1f1 solid;
    margin-left: auto;
    margin-right: auto;
  }

  .set-item-title {
    font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans",
      sans-serif !important;
    font-size: 15px;
    font-weight: 500;
    //width: 50%;
  }

  .value-box {
    height: 42px;
    box-shadow: none !important;
    border: rgb(194, 194, 194) 1px solid !important;
    border-radius: 4px !important;
    color: #3c4d62;
    font-size: 14px;
    font-weight: 500;
  }

  .value-box:focus {
    outline: none !important;
  }

  .remv-pad {
    padding-left: 0px;
  }

  .update-btn {
    width: 150px !important;
    border: 0px;
    //height: 55px;
    font-family: Nunito, sans-serif !important;
    font-weight: 600;
    color: white;
    background-color: var(--site-primary-color);
    opacity: 0.7;
  }

  .update-btn:focus {
    outline: none !important;
  }

  .update-btn:hover {
    background-color: #526484;
  }
}

.message-pop {
  .modal-btn {
    padding: 10px;
    border-radius: 5px;
    border: 0px;
    width: 150px;
  }
  .btn-section {
    display: inline-flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
  }

  .btn-confirm {
    font-family: Nunito, sans-serif !important;
    font-weight: 600;
    color: white;
    background-color: var(--site-primary-color);
    opacity: 0.7;
  }

  .btn-cancel {
    font-family: Nunito, sans-serif !important;
    font-weight: 600;
    color: white;
    background-color: #2d3d52;
    opacity: 0.7;
  }

  .btn-cancel:hover {
    background-color: #2d3d52;
    opacity: 0.9;
  }

  .btn-confirm:hover {
    background-color: var(--site-primary-color);
    opacity: 0.9;
  }

  .btn-confirm:focus {
    outline-color: #526484 !important;
  }

  .pop-title {
    font-family: Nunito, sans-serif !important;
    font-size: 14px;
    font-weight: 500;
  }
}
